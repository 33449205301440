.carousel-caption {
  position: relative;
  float: left;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.photo-cred {
  font-size: 0.75rem;
  color: rgb(136, 136, 136);
}

.photo-cred a,
.photo-cred a:hover {
  color: inherit;
  /* text-decoration: none; */
}
