/* section headers */
.section {
  color: rgb(204, 204, 204);
}

/* home styles */
.jumbotron-fluid {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    url("../resources/images/background/boston.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.greeting {
  padding: 25vmin 5vmin 3vmin 5vmin;
}

.greeting h1 {
  font-size: 12vmin;
}

.greeting h5 {
  font-size: 4vmin;
}

.btn {
  cursor: pointer;
}

.links {
  position: absolute;
  margin: 0 0 0 2rem;
  bottom: 7.5vmin;
  letter-spacing: 2rem;
  left: 0;
  right: 0;
}

i {
  display: inline-block;
  text-align: center;
  font-size: 6vmin;
  transition: 0.2s;
}

i:hover {
  color: rgb(149, 149, 149) !important;
}

/* about styles */
#about {
  padding-top: 5%;
}

.about-me {
  font-size: 1.25rem;
  color: rgb(204, 204, 204);
  white-space: pre-wrap;
}

/* projects styles */
@media (min-width: 800px) {
  .project-column {
    display: inline-block;
    max-width: 50%;
    vertical-align: top;
  }
}

@media (max-width: 800px) {
  .project-column {
    padding: 0 !important;
  }
}
