body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", "Roboto Condensed",
    "Roboto", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-weight: 300;
  background-color: rgb(8, 8, 8);
}

.container {
  padding: 2.5% 15%;
}

.navbar {
  background-color: rgb(34, 34, 34);
}

.home-button {
  all: unset;
}

.home-button:focus {
  outline: none;
}

.nav-item {
  margin: 0 0.3rem;
  padding: 1rem 0 0 0;
  font-size: 1.1rem;
  cursor: pointer;
}

.copyright {
  margin: 0;
  padding: 8vh 0 5vh 0;
  color: rgb(136, 136, 136);
}

/* section headers */
.section {
  color: rgb(204, 204, 204);
}

/* home styles */
.jumbotron-fluid {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    url(/static/media/boston.4dcef97d.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.greeting {
  padding: 25vmin 5vmin 3vmin 5vmin;
}

.greeting h1 {
  font-size: 12vmin;
}

.greeting h5 {
  font-size: 4vmin;
}

.btn {
  cursor: pointer;
}

.links {
  position: absolute;
  margin: 0 0 0 2rem;
  bottom: 7.5vmin;
  letter-spacing: 2rem;
  left: 0;
  right: 0;
}

i {
  display: inline-block;
  text-align: center;
  font-size: 6vmin;
  transition: 0.2s;
}

i:hover {
  color: rgb(149, 149, 149) !important;
}

/* about styles */
#about {
  padding-top: 5%;
}

.about-me {
  font-size: 1.25rem;
  color: rgb(204, 204, 204);
  white-space: pre-wrap;
}

/* projects styles */
@media (min-width: 800px) {
  .project-column {
    display: inline-block;
    max-width: 50%;
    vertical-align: top;
  }
}

@media (max-width: 800px) {
  .project-column {
    padding: 0 !important;
  }
}

.carousel-caption {
  position: relative;
  float: left;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.photo-cred {
  font-size: 0.75rem;
  color: rgb(136, 136, 136);
}

.photo-cred a,
.photo-cred a:hover {
  color: inherit;
  /* text-decoration: none; */
}

.card {
  border-color: rgb(34, 34, 34);
}

.card-subtitle {
  white-space: pre-wrap;
}

.card-body {
  background-color: rgb(34, 34, 34);
}

.card-title,
.card-text {
  color: rgb(204, 204, 204);
}

.project-btn {
  margin-right: 1rem;
  color: rgb(204, 204, 204);
}

